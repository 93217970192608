import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47_91articleSlug_93_46vueMeta } from "/var/www/html/shvr2024-fe/pages/articles/[articleSlug].vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47index_46vueMeta } from "/var/www/html/shvr2024-fe/pages/articles/index.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47coin_46vueMeta } from "/var/www/html/shvr2024-fe/pages/coin.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47contact_46vueMeta } from "/var/www/html/shvr2024-fe/pages/contact.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47event_46vueMeta } from "/var/www/html/shvr2024-fe/pages/event.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47faq_46vueMeta } from "/var/www/html/shvr2024-fe/pages/faq.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47forgot_45password_46vueMeta } from "/var/www/html/shvr2024-fe/pages/forgot-password.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47gallery_46vueMeta } from "/var/www/html/shvr2024-fe/pages/gallery.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47ggshiver_46vueMeta } from "/var/www/html/shvr2024-fe/pages/ggshiver.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47ground_45festival_47index_46vueMeta } from "/var/www/html/shvr2024-fe/pages/ground-festival/index.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47index_46vueMeta } from "/var/www/html/shvr2024-fe/pages/index.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47login_46vueMeta } from "/var/www/html/shvr2024-fe/pages/login.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47privacy_45policy_46vueMeta } from "/var/www/html/shvr2024-fe/pages/privacy-policy.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47index_46vueMeta } from "/var/www/html/shvr2024-fe/pages/profile/index.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47orders_46vueMeta } from "/var/www/html/shvr2024-fe/pages/profile/orders.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47setting_46vueMeta } from "/var/www/html/shvr2024-fe/pages/profile/setting.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47upload_45receipt_46vueMeta } from "/var/www/html/shvr2024-fe/pages/profile/upload-receipt.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47wishlists_46vueMeta } from "/var/www/html/shvr2024-fe/pages/profile/wishlists.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47register_46vueMeta } from "/var/www/html/shvr2024-fe/pages/register.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47request_45reset_45password_46vueMeta } from "/var/www/html/shvr2024-fe/pages/request-reset-password.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47reset_45password_46vueMeta } from "/var/www/html/shvr2024-fe/pages/reset-password.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47_91productSlug_93_46vueMeta } from "/var/www/html/shvr2024-fe/pages/shvrcoin/[productSlug].vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47checkout_46vueMeta } from "/var/www/html/shvr2024-fe/pages/shvrcoin/checkout.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47index_46vueMeta } from "/var/www/html/shvr2024-fe/pages/shvrcoin/index.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47mycart_46vueMeta } from "/var/www/html/shvr2024-fe/pages/shvrcoin/mycart.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47shipping_46vueMeta } from "/var/www/html/shvr2024-fe/pages/shvrcoin/shipping.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47_91placeSlug_93_46vueMeta } from "/var/www/html/shvr2024-fe/pages/shvrplace/[citySlug]/[placeSlug].vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47index_46vueMeta } from "/var/www/html/shvr2024-fe/pages/shvrplace/[citySlug]/index.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45conditions_46vueMeta } from "/var/www/html/shvr2024-fe/pages/terms-conditions.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45of_45use_46vueMeta } from "/var/www/html/shvr2024-fe/pages/terms-of-use.vue?macro=true";
import { default as _47var_47www_47html_47shvr2024_45fe_47pages_47verification_45success_46vueMeta } from "/var/www/html/shvr2024-fe/pages/verification-success.vue?macro=true";
export default [
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47_91articleSlug_93_46vueMeta?.name ?? "articles-articleSlug",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47_91articleSlug_93_46vueMeta?.path ?? "/articles/:articleSlug",
    file: "/var/www/html/shvr2024-fe/pages/articles/[articleSlug].vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47_91articleSlug_93_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47_91articleSlug_93_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47_91articleSlug_93_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/articles/[articleSlug].vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47index_46vueMeta?.name ?? "articles",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47index_46vueMeta?.path ?? "/articles",
    file: "/var/www/html/shvr2024-fe/pages/articles/index.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47index_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47index_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47articles_47index_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47coin_46vueMeta?.name ?? "coin",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47coin_46vueMeta?.path ?? "/coin",
    file: "/var/www/html/shvr2024-fe/pages/coin.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47coin_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47coin_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47coin_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/coin.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47contact_46vueMeta?.name ?? "contact",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47contact_46vueMeta?.path ?? "/contact",
    file: "/var/www/html/shvr2024-fe/pages/contact.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47contact_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47event_46vueMeta?.name ?? "event",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47event_46vueMeta?.path ?? "/event",
    file: "/var/www/html/shvr2024-fe/pages/event.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47event_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47event_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47event_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/event.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47faq_46vueMeta?.name ?? "faq",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47faq_46vueMeta?.path ?? "/faq",
    file: "/var/www/html/shvr2024-fe/pages/faq.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47faq_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47forgot_45password_46vueMeta?.name ?? "forgot-password",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47forgot_45password_46vueMeta?.path ?? "/forgot-password",
    file: "/var/www/html/shvr2024-fe/pages/forgot-password.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47forgot_45password_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47forgot_45password_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47forgot_45password_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47gallery_46vueMeta?.name ?? "gallery",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47gallery_46vueMeta?.path ?? "/gallery",
    file: "/var/www/html/shvr2024-fe/pages/gallery.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47gallery_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47gallery_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47gallery_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47ggshiver_46vueMeta?.name ?? "ggshiver",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47ggshiver_46vueMeta?.path ?? "/ggshiver",
    file: "/var/www/html/shvr2024-fe/pages/ggshiver.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47ggshiver_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47ggshiver_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47ggshiver_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/ggshiver.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47ground_45festival_47index_46vueMeta?.name ?? "ground-festival",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47ground_45festival_47index_46vueMeta?.path ?? "/ground-festival",
    file: "/var/www/html/shvr2024-fe/pages/ground-festival/index.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47ground_45festival_47index_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47ground_45festival_47index_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47ground_45festival_47index_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/ground-festival/index.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47index_46vueMeta?.name ?? "index",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47index_46vueMeta?.path ?? "/",
    file: "/var/www/html/shvr2024-fe/pages/index.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47index_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47index_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47login_46vueMeta?.name ?? "login",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47login_46vueMeta?.path ?? "/login",
    file: "/var/www/html/shvr2024-fe/pages/login.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47login_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47login_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47login_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47privacy_45policy_46vueMeta?.name ?? "privacy-policy",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47privacy_45policy_46vueMeta?.path ?? "/privacy-policy",
    file: "/var/www/html/shvr2024-fe/pages/privacy-policy.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47privacy_45policy_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47privacy_45policy_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47privacy_45policy_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47index_46vueMeta?.name ?? "profile",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47index_46vueMeta?.path ?? "/profile",
    file: "/var/www/html/shvr2024-fe/pages/profile/index.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47index_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47index_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47index_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47orders_46vueMeta?.name ?? "profile-orders",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47orders_46vueMeta?.path ?? "/profile/orders",
    file: "/var/www/html/shvr2024-fe/pages/profile/orders.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47orders_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47orders_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47orders_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/profile/orders.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47setting_46vueMeta?.name ?? "profile-setting",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47setting_46vueMeta?.path ?? "/profile/setting",
    file: "/var/www/html/shvr2024-fe/pages/profile/setting.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47setting_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47setting_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47setting_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/profile/setting.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47upload_45receipt_46vueMeta?.name ?? "profile-upload-receipt",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47upload_45receipt_46vueMeta?.path ?? "/profile/upload-receipt",
    file: "/var/www/html/shvr2024-fe/pages/profile/upload-receipt.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47upload_45receipt_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47upload_45receipt_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47upload_45receipt_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/profile/upload-receipt.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47wishlists_46vueMeta?.name ?? "profile-wishlists",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47wishlists_46vueMeta?.path ?? "/profile/wishlists",
    file: "/var/www/html/shvr2024-fe/pages/profile/wishlists.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47wishlists_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47wishlists_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47profile_47wishlists_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/profile/wishlists.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47register_46vueMeta?.name ?? "register",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47register_46vueMeta?.path ?? "/register",
    file: "/var/www/html/shvr2024-fe/pages/register.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47register_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47register_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47register_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47request_45reset_45password_46vueMeta?.name ?? "request-reset-password",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47request_45reset_45password_46vueMeta?.path ?? "/request-reset-password",
    file: "/var/www/html/shvr2024-fe/pages/request-reset-password.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47request_45reset_45password_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47request_45reset_45password_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47request_45reset_45password_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/request-reset-password.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47reset_45password_46vueMeta?.name ?? "reset-password",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47reset_45password_46vueMeta?.path ?? "/reset-password",
    file: "/var/www/html/shvr2024-fe/pages/reset-password.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47reset_45password_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47reset_45password_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47reset_45password_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47_91productSlug_93_46vueMeta?.name ?? "shvrcoin-productSlug",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47_91productSlug_93_46vueMeta?.path ?? "/shvrcoin/:productSlug",
    file: "/var/www/html/shvr2024-fe/pages/shvrcoin/[productSlug].vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47_91productSlug_93_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47_91productSlug_93_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47_91productSlug_93_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/shvrcoin/[productSlug].vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47checkout_46vueMeta?.name ?? "shvrcoin-checkout",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47checkout_46vueMeta?.path ?? "/shvrcoin/checkout",
    file: "/var/www/html/shvr2024-fe/pages/shvrcoin/checkout.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47checkout_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47checkout_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47checkout_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/shvrcoin/checkout.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47index_46vueMeta?.name ?? "shvrcoin",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47index_46vueMeta?.path ?? "/shvrcoin",
    file: "/var/www/html/shvr2024-fe/pages/shvrcoin/index.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47index_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47index_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47index_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/shvrcoin/index.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47mycart_46vueMeta?.name ?? "shvrcoin-mycart",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47mycart_46vueMeta?.path ?? "/shvrcoin/mycart",
    file: "/var/www/html/shvr2024-fe/pages/shvrcoin/mycart.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47mycart_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47mycart_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47mycart_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/shvrcoin/mycart.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47shipping_46vueMeta?.name ?? "shvrcoin-shipping",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47shipping_46vueMeta?.path ?? "/shvrcoin/shipping",
    file: "/var/www/html/shvr2024-fe/pages/shvrcoin/shipping.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47shipping_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47shipping_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrcoin_47shipping_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/shvrcoin/shipping.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47_91placeSlug_93_46vueMeta?.name ?? "shvrplace-citySlug-placeSlug",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47_91placeSlug_93_46vueMeta?.path ?? "/shvrplace/:citySlug/:placeSlug",
    file: "/var/www/html/shvr2024-fe/pages/shvrplace/[citySlug]/[placeSlug].vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47_91placeSlug_93_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47_91placeSlug_93_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47_91placeSlug_93_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/shvrplace/[citySlug]/[placeSlug].vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47index_46vueMeta?.name ?? "shvrplace-citySlug",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47index_46vueMeta?.path ?? "/shvrplace/:citySlug",
    file: "/var/www/html/shvr2024-fe/pages/shvrplace/[citySlug]/index.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47index_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47index_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47shvrplace_47_91citySlug_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/shvrplace/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45conditions_46vueMeta?.name ?? "terms-conditions",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45conditions_46vueMeta?.path ?? "/terms-conditions",
    file: "/var/www/html/shvr2024-fe/pages/terms-conditions.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45conditions_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45conditions_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45conditions_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45of_45use_46vueMeta?.name ?? "terms-of-use",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45of_45use_46vueMeta?.path ?? "/terms-of-use",
    file: "/var/www/html/shvr2024-fe/pages/terms-of-use.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45of_45use_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45of_45use_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47terms_45of_45use_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47html_47shvr2024_45fe_47pages_47verification_45success_46vueMeta?.name ?? "verification-success",
    path: _47var_47www_47html_47shvr2024_45fe_47pages_47verification_45success_46vueMeta?.path ?? "/verification-success",
    file: "/var/www/html/shvr2024-fe/pages/verification-success.vue",
    children: [],
    meta: _47var_47www_47html_47shvr2024_45fe_47pages_47verification_45success_46vueMeta,
    alias: _47var_47www_47html_47shvr2024_45fe_47pages_47verification_45success_46vueMeta?.alias || [],
    redirect: _47var_47www_47html_47shvr2024_45fe_47pages_47verification_45success_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/html/shvr2024-fe/pages/verification-success.vue").then(m => m.default || m)
  }
]