import { AxiosError } from 'axios';
import get from 'lodash/get';
import { useAuthStore } from '~~/store/auth';
import { PersonAttrs } from '~~/utils/models/Person';

type PersonData = NonNullable<PersonAttrs['data']>;
export interface AuthLoginData extends Pick<PersonData, 'email'> {
  password?: string;
  remember?: boolean;
}

export interface AuthRegisterData extends Omit<PersonData, 'city'> {
  provinceId?: number;
  city?: number;
  password?: string;
  passwordConfirmation?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export interface AuthForgotPasswordData extends Pick<PersonData, 'email'> {}

export interface AuthResetPasswordData
  extends Pick<AuthRegisterData, 'newPassword' | 'confirmNewPassword'> {
  code?: string;
}

class Auth {
  #nuxtApp;
  #accessTokenCookie;
  #store;

  constructor(nuxtApp: ReturnType<typeof useNuxtApp>) {
    this.#nuxtApp = nuxtApp;
    this.#store = useAuthStore();
    this.#accessTokenCookie = useCookie('auth/accessToken');
    this.#initAPI();
    this.#load();
  }

  get loggedIn() {
    return Boolean(this.#store.accessToken);
  }

  get person() {
    return this.#store.person;
  }

  /**
   * Log in.
   *
   * @param {Object} credentials
   */
  async login(credentials: AuthLoginData) {
    const response = await this.#nuxtApp.$api.post<{
      data: { accessToken: string };
    }>('v3/auth/login', credentials, { withCredentials: true });
    const data = response.data.data;

    this.#storeAccessToken(data.accessToken);
    this.fetchPerson();
    return response;
  }

  /**
   * Fethc user data.
   */
  async fetchPerson() {
    const response = await this.#nuxtApp.$api.get(`v1/auth/me`);
    this.#store.setPerson(response.data.data);
  }

  /**
   * Log out.
   */
  logout(askToLogIn = false) {
    this.#storeAccessToken(null);

    if (process.client) {
      const route = useRoute();
      location.href = askToLogIn ? `/login?redirectTo=${route.fullPath}` : '/';
    }
  }

  register(data: AuthRegisterData) {
    return this.#nuxtApp.$api.post('v3/auth/register', data);
  }

  forgotPassword(data: AuthForgotPasswordData) {
    return this.#nuxtApp.$api.post('v3/auth/forgot-password', data);
  }

  resetPassword(data: AuthResetPasswordData) {
    return this.#nuxtApp.$api.post('v3/auth/reset-password', data);
  }

  async update(data: AuthRegisterData) {
    const response = await this.#nuxtApp.$api.post('v3/auth/update', data);
    this.fetchPerson();
    return response;
  }

  resendVerification(data: AuthForgotPasswordData) {
    return this.#nuxtApp.$api.post('v3/auth/resend-verification', data);
  }

  /**
   * Initialize Axios API integration.
   */
  #initAPI() {
    const handleFailure = (err: AxiosError) => {
      console.warn(err);
      this.logout(true);

      return Promise.reject(err);
    };

    // Refresh token when expired.
    this.#nuxtApp.$api.interceptors.response.use(
      // Any status code that lie within the range of 2xx cause this function to
      // trigger
      (response) => response,
      // Any status codes that falls outside the range of 2xx cause this
      // function to trigger
      async (error) => {
        const code = get(error, 'response.data.error.code');

        switch (code) {
          case 'TokenExpiredError': {
            try {
              await this.#refreshToken();

              error.config.headers.Authorization =
                this.#nuxtApp.$api.defaults.headers.common['Authorization'];

              return this.#nuxtApp.$api.request(error.config);
            } catch (err) {
              return handleFailure(err as AxiosError);
            }
          }
          case 'JsonWebTokenError':
            return handleFailure(error as AxiosError);
        }

        return Promise.reject(error);
      }
    );
  }

  async #refreshToken() {
    const response = await this.#nuxtApp.$api.post<{
      data: { accessToken: string };
    }>('v1/auth/refresh-token', null, { withCredentials: true });
    const data = response.data.data;

    this.#storeAccessToken(data.accessToken);
  }

  /**
   * Load using saved data.
   */
  async #load() {
    const accessToken = this.#accessTokenCookie.value;

    if (accessToken) {
      this.#storeAccessToken(accessToken);
      this.person || (await this.fetchPerson());
    }
  }

  #storeAccessToken(accessToken: string | null) {
    this.#accessTokenCookie.value = accessToken;
    this.#store.setAccessToken(accessToken);
    this.#setAPIToken();
  }

  /**
   * Set API bearer token.
   */
  #setAPIToken() {
    const token =
      this.#store.accessToken && `Bearer ${this.#store.accessToken}`;
    this.#nuxtApp.$api.defaults.headers.common['Authorization'] = token;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const auth: Auth = new Auth(nuxtApp as ReturnType<typeof useNuxtApp>);
  return { provide: { auth } };
});
